import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  IntroText,
  PrimaryPagesHero,
  SectionHeading,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { CentersGrid, Seo } from "../../components/elements"

const ContactUs = ({ data, pageContext }) => {
  const page = data.datoCmsCancerContact
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box width="full">
                <IntroText>{page.introContent}</IntroText>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>

        {/* Centers */}
        <Section id="locations" bg="lightgray">
          <Container>
            <Flex flexWrap="wrap">
              <Box width="full">
                <SectionHeading textAlign="center">
                  Locations – Care Close to Home
                </SectionHeading>
              </Box>
              <CentersGrid />
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  {
    datoCmsCancerContact {
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
